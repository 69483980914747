import React from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { RelatedPostProps } from './RelatedPost.types';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import {
  PostContainer,
  Carrousel,
  DataContainer,
  ImageContainer,
  MoreInfoContainer,
  MoreInfoText,
  SubtitleContainer,
  Tag,
  Title,
  TitleContainer,
} from './RelatedPost.styles';
import { transformToSlug } from 'helpers/slugHelper';

const RelatedPost = ({ relatedPost, isWebinar }: RelatedPostProps) => {
  SwiperCore.use([Navigation]);

  if (relatedPost.length > 0) {
    return (
      <GlobalWrapper>
        <Title>Related Content</Title>
        <Carrousel
          slidesPerView={1}
          spaceBetween={33}
          breakpoints={{
            1280: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 'auto',
            },
          }}
        >
          {relatedPost.map((post) => (
            <SwiperSlide key={post.attributes.Title} className="swiper-slide">
              <PostContainer
                href={`/${isWebinar ? 'training' : 'blog'}/${transformToSlug(
                  post.attributes.Title.toLowerCase(),
                )}`}
              >
                <ImageContainer>
                  <Tag isWebinar={isWebinar}>{isWebinar ? 'Training' : 'Article'}</Tag>
                  {CMSParseImage(post.attributes.Small_Image.data)}
                </ImageContainer>
                <DataContainer className="article-data">
                  <TitleContainer>{post.attributes.Title}</TitleContainer>
                  <SubtitleContainer>{post.attributes.Subtitle}</SubtitleContainer>
                  {!isWebinar && (
                    <MoreInfoContainer>
                      <MoreInfoText>{post.attributes.Author}</MoreInfoText>
                      <MoreInfoText>{post.attributes.Time_To_Read}</MoreInfoText>
                    </MoreInfoContainer>
                  )}
                </DataContainer>
              </PostContainer>
            </SwiperSlide>
          ))}
        </Carrousel>
      </GlobalWrapper>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default RelatedPost;
