import React from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { transformToSlug } from 'helpers/slugHelper';
import { MostPopularProps } from './mostpopular.types';
import {
  ArticleLink,
  ArticleTitle,
  AuthorData,
  BoxContainer,
  Slash,
  TitleContainer,
  WhiteSpace,
  Wrapper,
} from './mostPopular.styles';

const MostPopular = ({ mostPopular, isWebinar }: MostPopularProps) => {
  return (
    <GlobalWrapper>
      <Wrapper>
        <TitleContainer>Most Popular</TitleContainer>
        {mostPopular.map((article) => (
          <BoxContainer>
            <AuthorData>
              {isWebinar ? article.attributes.Schedule : article.attributes.Author} <Slash>|</Slash>{' '}
              <WhiteSpace />
              {isWebinar ? article.attributes.Speaker : article.attributes.Publication_Date}
            </AuthorData>
            <ArticleTitle
              href={`/${isWebinar ? 'training' : 'blog'}/${transformToSlug(
                article.attributes.Title.toLowerCase(),
              )}`}
            >
              {article.attributes.Title}
            </ArticleTitle>
            <ArticleLink href={`/${isWebinar ? 'training' : 'blog'}`}>
              {isWebinar ? 'Training' : 'Article'}
            </ArticleLink>
          </BoxContainer>
        ))}
      </Wrapper>
    </GlobalWrapper>
  );
};

export default MostPopular;
