import { COLORS } from '@global/styles/colors';
import { FONT } from '@global/styles/default';
import { MOBILE_575, TABLET_768 } from '@global/styles/sizes';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const PostContainer = styled.a`
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    .article-data {
      background-color: #e2e8f0;
    }
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 140px;
  position: relative;
  border-radius: 8px 8px 0 0;

  img {
    height: 140px !important;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
    object-position: center;
  }
`;

export const Tag = styled.div<{ isWebinar?: boolean }>`
  width: ${({ isWebinar }) => (isWebinar ? '101px' : '76px')};
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: ${({ isWebinar }) => (isWebinar ? COLORS.violetRed : COLORS.Green)};
  border-radius: 0 8px 0 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: ${COLORS.white};
`;

export const DataContainer = styled.div`
  padding: 16px 16px 13px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 0 0 8px 8px;
  border: 1px solid #d5d5d5;
  border-top: none;
`;

export const TitleContainer = styled.div`
  min-height: 52px;
  font-family: 'Poppins';
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubtitleContainer = styled.div`
  min-height: 60px;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.resolutionBlue};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MoreInfoContainer = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;

export const MoreInfoText = styled.div`
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 16px;
  color: #252a34;
`;

export const Carrousel = styled(Swiper)`
  width: 80%;
  height: 100%;
  margin: 0 auto;
  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
  .swiper-slide {
    max-width: 322px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${MOBILE_575}px) {
      max-width: 300px;
    }
  }

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
  }
`;

export const Title = styled.div`
  margin: 90px 0 29px;
  font-family: ${FONT.ulmGrotesk};
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  color: #000000;
`;
